import { StaticImageData } from "next/image";

import BlurImage from "./BlurImage";

type Props = {
  name: string;
  title: string;
  testimonial: string;
  image: StaticImageData;
};

const Testimonial = (props: Props) => {
  return (
    <section className="overflow-hidden bg-gray-100">
      <div className="px-4 pt-20 pb-12 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-20">
        <div className="lg:flex lg:items-center">
          <div className="relative hidden w-64 h-64 lg:block lg:flex-shrink-0">
            <BlurImage
              className="rounded-full"
              src={props.image}
              alt={props.name}
              fill
            />
          </div>

          <div className="relative lg:ml-10">
            <blockquote className="relative">
              <div className="text-2xl font-medium leading-9 text-gray-900">
                <p>{props.testimonial}</p>
              </div>
              <footer className="mt-8">
                <div className="flex">
                  <div className="flex-shrink-0 lg:hidden">
                    <BlurImage
                      className="w-12 h-12 rounded-full"
                      src={props.image}
                      alt={props.name}
                    />
                  </div>
                  <div className="ml-4 lg:ml-0">
                    <div className="text-base font-medium text-gray-900">
                      {props.name}
                    </div>
                    <div className="text-base font-medium text-green-600">
                      {props.title}
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
