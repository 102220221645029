import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import Link from "next/link";
import { usePathname } from "next/navigation";

const ContactForm = () => {
  const path = usePathname();

  return (
    <div className="relative bg-white" id="contact">
      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div className="px-4 py-16 bg-green-400 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-white sm:text-3xl">
              Bize Ulaşın
            </h2>
            <dl className="mt-8 text-base text-white">
              <div>
                <dt className="sr-only">Posta adresi</dt>
                <dd>
                  <p>Halkapınar Mahallesi 1202/2 Sokak</p>
                  <p>Gıda Çarşısı İş Hanı No:31/816 İzmir</p>
                </dd>
              </div>
              <a href="tel:+905469781001">
                <div className="mt-6">
                  <dt className="sr-only">Phone number</dt>
                  <dd className="flex">
                    <PhoneIcon
                      className="flex-shrink-0 w-6 h-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">+90 (546) 978 10 01</span>
                  </dd>
                </div>
              </a>
              <a href="mailto:info@binbircare.com.tr">
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <MailIcon
                      className="flex-shrink-0 w-6 h-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">info@binbircare.com.tr</span>
                  </dd>
                </div>
              </a>
            </dl>
          </div>
        </div>
        <div className="px-4 py-16 bg-white sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form
              action="https://formspree.io/f/xgerejwd"
              method="POST"
              className="grid grid-cols-1 gap-y-6"
            >
              <input type="hidden" name="_language" value="tr" />
              <input type="hidden" name="page" value={path} />
              <div>
                <label htmlFor="full-name" className="sr-only">
                  İsim
                </label>
                <input
                  type="text"
                  name="full-name"
                  id="full-name"
                  autoComplete="name"
                  className="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="İsim"
                />
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Eposta
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Eposta"
                />
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  Telefon
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  className="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Telefon"
                />
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Mesaj
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full px-4 py-3 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Mesaj"
                  defaultValue={""}
                />
              </div>
              <div className="flex items-center">
                <input
                  id="gdpr-consent"
                  name="gdpr-consent"
                  type="checkbox"
                  className="w-4 h-4 text-green-400 border-gray-300 rounded focus:ring-green-500"
                  required
                />
                <label
                  htmlFor="gdpr-consent"
                  className="block ml-2 text-sm text-gray-900"
                >
                  <Link
                    href="/aydinlatma-metni"
                    className="font-semibold text-green-600"
                  >
                    İnternet Kullanıcıları Aydınlatma Metni
                  </Link>{" "}
                  okudum ve kabul ediyorum.
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="communication-consent"
                  name="communication-consent"
                  type="checkbox"
                  className="w-4 h-4 text-green-400 border-gray-300 rounded focus:ring-green-500"
                />
                <label
                  htmlFor="communication-consent"
                  className="block ml-2 text-sm text-gray-900"
                >
                  <Link
                    href="/ticari-ileti-goenderilmesine-iliskin-acik-riza-beyani"
                    className="font-semibold text-green-600"
                  >
                    Ticari İletilerin Gönderilmesine İlişkin Açık Rıza Metnini
                  </Link>{" "}
                  okudum ve kabul ediyorum.
                </label>
              </div>
              <div>
                <button
                  type="submit"
                  className="inline-flex justify-center px-6 py-3 text-base font-medium text-white bg-green-400 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Gönder
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
