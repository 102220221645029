import { GetStaticProps } from "next";
import Image from "next/image";
import Link from "next/link";
import { NextSeo } from "next-seo";

import BlurImage from "../components/BlurImage";
import ContactForm from "../components/ContactForm";
import Layout from "../components/Layout";
import MenuCard from "../components/MenuCard";
import Testimonial from "../components/Testimonial";
import getCommonProps, { CommonProps } from "../lib/getCommonProps";
import { categories } from "../lib/menus";
import siteUrl from "../lib/siteUrl";
import BinbircareLogo from "../public/1001care-logo.svg";
import DrBirsel from "../public/dr-birsel-uckun.jpg";
import RayonexLogo from "../public/rayonex-logo.png";

type Props = CommonProps;

const IndexPage = ({ ...commonProps }: Props) => (
  <Layout {...commonProps}>
    <NextSeo
      title="Binbircare Sağlık & Yazılım - Sağlık ve Teknolojinin Buluştuğu Yer"
      description="Binbircare Sağlık ve Yazılım Rayonex biorezonans cihazlarının Türkiye distribütörüdür."
      canonical={siteUrl}
    />
    <section className="bg-right bg-no-repeat bg-auto lg:bg-center lg:relative bg-hero">
      <div className="w-full pt-16 pb-20 mx-auto text-center max-w-7xl lg:py-24 lg:text-left">
        <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
            <span className="block text-green-400 xl:inline">
              Sebep temelli
            </span>{" "}
            <span className="block xl:inline">
              tedavi yaklaşımı en kalıcı çözümdür.
            </span>
          </h1>
          <span className="block pt-4 italic font-semibold text-right">
            Paul Schmidt
          </span>
          <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow">
              <Link
                href="/rayonex"
                className="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white bg-green-400 border border-transparent rounded-md hover:bg-green-700 md:py-4 md:text-lg md:px-10"
              >
                Ürünleri İnceleyin
              </Link>
            </div>
            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
              <Link
                href="#contact"
                className="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-green-600 bg-white border border-transparent rounded-md hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
              >
                Bize Ulaşın
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <BlurImage
          className="absolute inset-0 w-1/2 mx-auto my-auto"
          src={RayonexLogo}
          alt="Rayonex Logo"
        />
      </div>
    </section>
    <div className="bg-gray-100">
      <div className="pb-24 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <h2 className="py-16 text-4xl font-bold text-center text-gray-900">
          Rayonex Biorezonans Cihazları
        </h2>
        <div className="max-w-2xl mx-auto lg:max-w-none">
          <div className="lg:grid lg:grid-cols-3 lg:gap-x-6">
            {categories.map((category) => (
              <MenuCard
                name={category.name}
                image={category.image}
                link={category.href || "#"}
                key={category.name}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
    <section>
      <Link href="/kurumsal">
        <div className="flex justify-center px-4 cursor-pointer">
          <div className="flex lg:w-2/3 ">
            <div className="flex flex-row flex-wrap items-center flex-1 pt-12 md:flex-row-reverse">
              <div className="flex justify-center w-full md:w-1/2">
                <Image
                  src={BinbircareLogo}
                  height={250}
                  width={250}
                  alt="Binbircare Logo"
                />
              </div>
              <div className="flex flex-col justify-center md:w-1/2">
                <div className="flex flex-col my-8">
                  <h3 className="mb-2 text-2xl font-semibold font-title">
                    Hakkımızda
                  </h3>
                  <p className="mt-8">
                    Binbirçare Bilişim Teknolojileri Danışmanlık olarak Şubat
                    2020 yılından beri Alman RAYONEX Biomedical GmbH
                    üreticisinin Türkiye distribütörüyüz. Rayonex 40 yılı aşkın
                    süredir Biorezonans cihazları üreten bir firmadır. Dünyanın
                    46 ülkesinde cihazları kullanılmaktadır.
                  </p>
                  <p className="mt-8">
                    Binbirçare olarak, başta sağlık yazılımları olmak üzere,
                    modern, modüler, ölçeklendirilebilir ve sürdürülebilir
                    yazılımlar üretiyoruz. Geniş bilişim teknolojileri uzmanları
                    ağımız sayesinde, ihtiyaçlarınız için optimal çözümler
                    yaratmak için çalışıyoruz.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </section>
    <div className="py-10">
      <Testimonial
        name="Dr. Birsel Uçkun"
        title="Rayonex Türkiye Eğitmen Doktoru"
        testimonial="Alman üretimi Rayonex markası akupunktur temelli biorezonans tedavisi yaklaşımıyla ilgili literatürde önemli bir yere sahip ve şu anda 46 ülkede kullanılmakta. Türkiye'de Rayonex'in distribütörlüğünü yürüten Binbirçare şirketi mevcut kadrosu ve yönetim tarzı ile Paul Schmidt’e göre biorezonans tedavi yaklaşımının tanıtılması ve yaygınlaştırılması konusunda yoğun bir şekilde çalışmaktadır. Rayonex Rayocomp cihaz kullanıcılarını eğitimler ve her yeni gelişme ile ilgili bilgilendirmektedir. Binbirçare’nin her geçen gün büyüyen Rayonex ailesi ile daha da başarılı olacağına olan inancım tam."
        image={DrBirsel}
      />
    </div>
    <ContactForm />
  </Layout>
);

export const getStaticProps: GetStaticProps = async (context) => {
  const commonProps = await getCommonProps(context);

  return { props: { ...commonProps } };
};

export default IndexPage;
